import React from 'react'
import * as pageBodyStyle from './css/pageHeader.module.css'
function PageHeader (props) {
  return (
    <>
      <div className={`container-fluid p-0 mb-5 ${pageBodyStyle.containerFluid}`}>
        <h1 className={`body-title  ${pageBodyStyle.bodyTitle}`}>{props.text}</h1>
      </div>
    </>
  )
}

export default PageHeader
