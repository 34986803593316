import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import IntensivutbildningImg from '../../images/utbildningar/individuell_intensivutbildning.jpg'
const IndividuellIntensivutbildning = () => (
  <Layout>
    <Seo title='Individuell intensivutbildning' />
    <PageHeader text='Individuell intensivutbildning' />
    <div className='container'>
      <div className='row justify-content-around'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 mb-3'>
          <div>
            <h2>Anpassad efter dina personliga förutsättningar</h2>
          </div>

          <div>

            <p>
              När du bokar en individuell intensivutbildning skräddarsyr vi utbildningen så schemat anpassas efter din utbildningsnivå.
              Våra intensivutbildningsalternativ är alltså helt individuella utifrån våra elevers behov.
              En anpassad intensivutbildning kan se ut på många olika sätt.
              Alltifrån att du kör 2-3 lektioner per dag under ett par veckor till ett par lektioner per vecka.
            </p>
          </div>

          <div>
            <h3>Hur lång tid</h3>
            <p>
              Hur lång tid det tar att genomföra en anpassad intensivutbildning är som sagt högst individuellt.
              Viktiga faktorer är hur lätt du har för att lära dig samt om du har möjlighet att övningsköra privat mellan dina lektioner.
            </p>
          </div>

        </div>

        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3 text-center'>
          <img src={IntensivutbildningImg} alt='individuell intensivutbildning' className='img-fluid rounded' />
          <p className='mb-1 text-sm-center'>
            <Link to='/kontakta-oss/utbildningsanmalan' className='btn btn-danger'> Gör din anmälan här</Link>
          </p>
        </div>

      </div>
    </div>
  </Layout>
)

export default IndividuellIntensivutbildning
